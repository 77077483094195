export var CredentialsKeyEnum;
(function (CredentialsKeyEnum) {
    CredentialsKeyEnum["ApiKey"] = "apiKey";
    CredentialsKeyEnum["User"] = "user";
    CredentialsKeyEnum["SecretKey"] = "secretKey";
    CredentialsKeyEnum["Domain"] = "domain";
    CredentialsKeyEnum["Password"] = "password";
    CredentialsKeyEnum["Host"] = "host";
    CredentialsKeyEnum["Port"] = "port";
    CredentialsKeyEnum["Secure"] = "secure";
    CredentialsKeyEnum["Region"] = "region";
    CredentialsKeyEnum["AccountSid"] = "accountSid";
    CredentialsKeyEnum["MessageProfileId"] = "messageProfileId";
    CredentialsKeyEnum["Token"] = "token";
    CredentialsKeyEnum["From"] = "from";
    CredentialsKeyEnum["SenderName"] = "senderName";
    CredentialsKeyEnum["ApplicationId"] = "applicationId";
    CredentialsKeyEnum["ClientId"] = "clientId";
    CredentialsKeyEnum["ProjectName"] = "projectName";
    CredentialsKeyEnum["ServiceAccount"] = "serviceAccount";
    CredentialsKeyEnum["BaseUrl"] = "baseUrl";
    CredentialsKeyEnum["WebhookUrl"] = "webhookUrl";
    CredentialsKeyEnum["RequireTls"] = "requireTls";
    CredentialsKeyEnum["IgnoreTls"] = "ignoreTls";
    CredentialsKeyEnum["TlsOptions"] = "tlsOptions";
})(CredentialsKeyEnum || (CredentialsKeyEnum = {}));
export var EmailProviderIdEnum;
(function (EmailProviderIdEnum) {
    EmailProviderIdEnum["EmailJS"] = "emailjs";
    EmailProviderIdEnum["Mailgun"] = "mailgun";
    EmailProviderIdEnum["Mailjet"] = "mailjet";
    EmailProviderIdEnum["Mandrill"] = "mandrill";
    EmailProviderIdEnum["CustomSMTP"] = "nodemailer";
    EmailProviderIdEnum["Postmark"] = "postmark";
    EmailProviderIdEnum["SendGrid"] = "sendgrid";
    EmailProviderIdEnum["Sendinblue"] = "sendinblue";
    EmailProviderIdEnum["SES"] = "ses";
    EmailProviderIdEnum["NetCore"] = "netcore";
    EmailProviderIdEnum["Infobip"] = "infobip-email";
    EmailProviderIdEnum["Resend"] = "resend";
    EmailProviderIdEnum["MailerSend"] = "mailersend";
    EmailProviderIdEnum["Clickatell"] = "clickatell";
    EmailProviderIdEnum["Outlook365"] = "outlook365";
    EmailProviderIdEnum["Novu"] = "novu-email";
    EmailProviderIdEnum["SparkPost"] = "sparkpost";
})(EmailProviderIdEnum || (EmailProviderIdEnum = {}));
export var SmsProviderIdEnum;
(function (SmsProviderIdEnum) {
    SmsProviderIdEnum["Nexmo"] = "nexmo";
    SmsProviderIdEnum["Plivo"] = "plivo";
    SmsProviderIdEnum["Sms77"] = "sms77";
    SmsProviderIdEnum["SmsCentral"] = "sms-central";
    SmsProviderIdEnum["SNS"] = "sns";
    SmsProviderIdEnum["Telnyx"] = "telnyx";
    SmsProviderIdEnum["Twilio"] = "twilio";
    SmsProviderIdEnum["Gupshup"] = "gupshup";
    SmsProviderIdEnum["Firetext"] = "firetext";
    SmsProviderIdEnum["Infobip"] = "infobip-sms";
    SmsProviderIdEnum["BurstSms"] = "burst-sms";
    SmsProviderIdEnum["Clickatell"] = "clickatell";
    SmsProviderIdEnum["FortySixElks"] = "forty-six-elks";
    SmsProviderIdEnum["Kannel"] = "kannel";
    SmsProviderIdEnum["Maqsam"] = "maqsam";
    SmsProviderIdEnum["Termii"] = "termii";
})(SmsProviderIdEnum || (SmsProviderIdEnum = {}));
export var ChatProviderIdEnum;
(function (ChatProviderIdEnum) {
    ChatProviderIdEnum["Slack"] = "slack";
    ChatProviderIdEnum["Discord"] = "discord";
    ChatProviderIdEnum["MsTeams"] = "msteams";
    ChatProviderIdEnum["Mattermost"] = "mattermost";
})(ChatProviderIdEnum || (ChatProviderIdEnum = {}));
export var PushProviderIdEnum;
(function (PushProviderIdEnum) {
    PushProviderIdEnum["FCM"] = "fcm";
    PushProviderIdEnum["APNS"] = "apns";
    PushProviderIdEnum["EXPO"] = "expo";
})(PushProviderIdEnum || (PushProviderIdEnum = {}));
export var InAppProviderIdEnum;
(function (InAppProviderIdEnum) {
    InAppProviderIdEnum["Novu"] = "novu";
})(InAppProviderIdEnum || (InAppProviderIdEnum = {}));
