export var DigestUnitEnum;
(function (DigestUnitEnum) {
    DigestUnitEnum["SECONDS"] = "seconds";
    DigestUnitEnum["MINUTES"] = "minutes";
    DigestUnitEnum["HOURS"] = "hours";
    DigestUnitEnum["DAYS"] = "days";
})(DigestUnitEnum || (DigestUnitEnum = {}));
export var DigestTypeEnum;
(function (DigestTypeEnum) {
    DigestTypeEnum["REGULAR"] = "regular";
    DigestTypeEnum["BACKOFF"] = "backoff";
})(DigestTypeEnum || (DigestTypeEnum = {}));
export var DelayTypeEnum;
(function (DelayTypeEnum) {
    DelayTypeEnum["REGULAR"] = "regular";
    DelayTypeEnum["SCHEDULED"] = "scheduled";
})(DelayTypeEnum || (DelayTypeEnum = {}));
